var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500px", persistent: "" },
      model: {
        value: _vm.aICreditsDialog,
        callback: function ($$v) {
          _vm.aICreditsDialog = $$v
        },
        expression: "aICreditsDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex align-center justify-center flex-column" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
            },
            [
              _vm.creditsDetails.mode === "individual"
                ? _c("h5", [_vm._v("Modify AI Credits limit for the user")])
                : _vm._e(),
              _vm.creditsDetails.mode === "global"
                ? _c("h5", [_vm._v("Modify AI Credits limit for all users")])
                : _vm._e(),
              _c(
                "v-icon",
                {
                  staticClass: "white--text",
                  attrs: { disabled: _vm.saving },
                  on: {
                    click: function ($event) {
                      return _vm.aICreditsDialogClose()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              [
                _c(
                  "v-card",
                  { staticClass: " align-start px-6 py-8" },
                  [
                    _vm.creditsDetails.mode === "global" &&
                    _vm.companyWithManualLimit.length
                      ? _c(
                          "div",
                          [
                            _c("span", { staticClass: "subtitle--text-1" }, [
                              _vm._v(
                                " Companies having manually set AI credit limit"
                              ),
                            ]),
                            _c(
                              "v-list",
                              {
                                staticStyle: {
                                  "max-height": "150px",
                                  "overflow-y": "auto",
                                },
                              },
                              _vm._l(
                                _vm.companyWithManualLimit,
                                function (item) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.TeamID,
                                      staticStyle: {
                                        "min-height": "34px",
                                        "padding-left": "0",
                                      },
                                      attrs: {
                                        title: item.Email,
                                        subtitle: "...",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.Email) +
                                          " (" +
                                          _vm._s(item.CompanyName) +
                                          ")"
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-form",
                      {
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex w-100" },
                          [
                            _c("v-text-field", {
                              staticClass: "rounded-md no-field-details mb-0",
                              attrs: {
                                required: "",
                                outlined: "",
                                dense: "",
                                "prepend-inner-icon": "mdi-currency-usd",
                                rules: _vm.aiCreditsRules,
                              },
                              model: {
                                value: _vm.usageLimit,
                                callback: function ($$v) {
                                  _vm.usageLimit = $$v
                                },
                                expression: "usageLimit",
                              },
                            }),
                            _vm.creditsDetails.mode === "individual" ||
                            (_vm.creditsDetails.mode === "global" &&
                              _vm.companyWithManualLimit.length == 0)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "rounded-md success--text mx-3",
                                    attrs: {
                                      width: "36",
                                      height: "40",
                                      depressed: "",
                                      outlined: "",
                                      disabled:
                                        _vm.saving ||
                                        !_vm.valid ||
                                        _vm.originalUsageLimit ==
                                          _vm.usageLimit,
                                      loading: _vm.saving,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyAction()
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-check")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.creditsDetails.mode === "individual" ||
                            (_vm.creditsDetails.mode === "global" &&
                              _vm.companyWithManualLimit.length == 0)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "rounded-md error--text",
                                    attrs: {
                                      width: "36",
                                      height: "40",
                                      depressed: "",
                                      outlined: "",
                                      disabled: _vm.saving,
                                    },
                                    on: { click: _vm.aICreditsDialogClose },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "w-100" },
                          [
                            _vm.creditsDetails.mode === "global" &&
                            _vm.companyWithManualLimit.length
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "rounded-md success--text mr-3",
                                    attrs: {
                                      depressed: "",
                                      outlined: "",
                                      disabled:
                                        _vm.saving ||
                                        !_vm.valid ||
                                        _vm.originalUsageLimit ==
                                          _vm.usageLimit,
                                      loading: _vm.saving,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyAction()
                                      },
                                    },
                                  },
                                  [_vm._v("Modify all")]
                                )
                              : _vm._e(),
                            _vm.creditsDetails.mode === "global" &&
                            _vm.companyWithManualLimit.length
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "rounded-md success--text mx-3",
                                    attrs: {
                                      depressed: "",
                                      outlined: "",
                                      disabled:
                                        _vm.saving1 ||
                                        !_vm.valid ||
                                        _vm.originalUsageLimit ==
                                          _vm.usageLimit,
                                      loading: _vm.saving1,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeCreditsGlobalLimited()
                                      },
                                    },
                                  },
                                  [_vm._v("Modify all excluding above teams")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }