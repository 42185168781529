
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { Line } from 'vue-chartjs'

export default Vue.extend({
  name: 'LineChart',
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  },
  watch: {
    chartdata: function(data) {
      this.renderChart(data, this.options);
    }
  }
})
