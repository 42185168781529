
















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DELETE_COMPANY } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    companyDeletionMetadata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      teamID: null,
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    companyDeletionMetadata(data) {
      this.dialog = true;
      this.teamID = data.TeamID;
    },
  },
  methods: {
    async deleteCompany() {
      this.deleting = true;

      await this.$apollo
      .query({
        query: DELETE_COMPANY, 
        variables: {
          TeamID: this.teamID,
        },
      })
      .then((res) => {
        this.$notify.success("Company deleted");
        this.$emit('onDeleteCompany')
      })
      .catch((error) => {
        this.$notify.error("Something went wrong");
      });
      
      this.deleting = false;
      this.dialog = false;
    },
  },
});
