var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                },
                [
                  _c("h5", [
                    _vm._v(" " + _vm._s(_vm.$t("switchAccount.title"))),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text",
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-8" },
                [
                  _c(
                    "v-icon",
                    { staticClass: " mr-3", attrs: { color: "secondary" } },
                    [_vm._v("mdi-login-variant")]
                  ),
                  _c("v-card-title", { staticClass: "text-subtitle-1 pa-0" }, [
                    _vm._v(_vm._s(_vm.$t("switchAccount.msg"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "100px",
                        color: " ",
                        disabled: _vm.deleting,
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("switchAccount.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "60px",
                        color: "primary",
                        disabled: _vm.deleting,
                        loading: _vm.deleting,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchUser()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("switchAccount.ok")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.switching,
            callback: function ($$v) {
              _vm.switching = $$v
            },
            expression: "switching",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex align-center px-6 py-8" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary", size: "36" },
                  }),
                  _c("br"),
                  _c("h3", [_vm._v(_vm._s(_vm.$t("switchAccount.switching")))]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }