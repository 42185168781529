var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "box-header d-flex justify-space-between align-center pa-3",
      },
      [
        _c("h5", { staticClass: "mb-0" }, [
          _vm._v(_vm._s(_vm.$t("labels.charts"))),
        ]),
        _c("v-spacer"),
        _c(
          "v-sheet",
          { attrs: { color: "white", elevation: "0", width: "120" } },
          [
            _c("v-select", {
              staticClass: "no-field-details small-select",
              attrs: {
                height: "28",
                items: _vm.granularityList,
                "item-value": "value",
                "item-text": "text",
                outlined: "",
                dense: "",
              },
              on: { change: _vm.emitGranularityChange },
              model: {
                value: _vm.granularity,
                callback: function ($$v) {
                  _vm.granularity = $$v
                },
                expression: "granularity",
              },
            }),
            _c("v-select", {
              staticClass: "no-field-details small-select",
              attrs: {
                height: "28",
                items: _vm.appList,
                "item-value": "metadata.app.adamId",
                "item-text": "metadata.app.appName",
                outlined: "",
                dense: "",
              },
              on: { change: _vm.emitAppChange },
              model: {
                value: _vm.selectedApp,
                callback: function ($$v) {
                  _vm.selectedApp = $$v
                },
                expression: "selectedApp",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "charts d-flex align-center justify-center pa-3" },
      [
        _c(
          "div",
          { staticClass: "mb-0 body-2 grey--text" },
          [
            _c(
              "div",
              { staticClass: "pr-0 custom-small-chip" },
              [
                _c("v-select", {
                  attrs: {
                    "menu-props": "closeOnContentClick",
                    items: _vm.datasetsList,
                    "item-text": "label",
                    attach: "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("labels.selectDs"),
                    "no-data-text": _vm.$t("dropdowns.noData"),
                    "return-object": "",
                    multiple: "",
                  },
                  on: { change: _vm.changeSelectedDatasets },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                close: true,
                                small: "",
                                "text-color": "white",
                                color: item.borderColor,
                              },
                              on: {
                                "click:close": function ($event) {
                                  return _vm.removeChipByLabel(item.label)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.label))]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.selectedDatasets,
                    callback: function ($$v) {
                      _vm.selectedDatasets = $$v
                    },
                    expression: "selectedDatasets",
                  },
                }),
              ],
              1
            ),
            _c("line-chart", {
              attrs: {
                width: 600,
                height: 300,
                chartdata: _vm.linechartData,
                options: _vm.options,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }