var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex align-center justify-center flex-column" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
            },
            [
              _c("h5", [_vm._v(" Archive Staff")]),
              _c(
                "v-icon",
                {
                  staticClass: "white--text",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "d-flex flex-column align-start px-6 py-8 pb-2" },
            [
              _c("v-card-title", { staticClass: "text-subtitle-1 pa-0" }, [
                _vm._v(
                  " Select companies from which you want to archive staff? "
                ),
              ]),
              _c("v-select", {
                staticClass: "px-0 mt-5 w-100",
                attrs: {
                  items: _vm.companiesList,
                  "item-text": "CompanyName",
                  "item-value": "TeamID",
                  attach: "",
                  dense: "",
                  outlined: "",
                  multiple: "",
                  label: "Select Company",
                },
                model: {
                  value: _vm.selectedCompanies,
                  callback: function ($$v) {
                    _vm.selectedCompanies = $$v
                  },
                  expression: "selectedCompanies",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass:
                "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { width: "100px", disabled: _vm.deleting, color: " " },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("deleteCampaign.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "60px",
                    disabled: _vm.deleting || _vm.selectedCompanies.length == 0,
                    loading: _vm.deleting,
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.archiveStaff()
                    },
                  },
                },
                [_vm._v(" Ok ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }