
























































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { COMPANY_LIST_FOR_STAFF, DELETE_TEAM_MEMBER } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    archiveStaffMemberData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
      companiesList: [],
      selectedCompanies: [],
      email: null
    };
  },
  watch: {
    archiveStaffMemberData(data) {
      this.dialog = true;
      this.email = data.email
      this.getCompanyList(data.email)
    },
  },
  methods: {
    async getCompanyList(email: string) {
      await this.$apollo.query({
        query: COMPANY_LIST_FOR_STAFF,
        variables: {
          email: email
        }
      })
      .then((res) => {
          this.companiesList = res.data.companyListForStaff.content[0]
        })
        .catch((error) => {
          this.$notify.error("Something went wrong");
        });
    },

    async archiveStaff() {
      this.deleting = true
      await this.$apollo.query({
        query: DELETE_TEAM_MEMBER,
        variables: {
          email: this.email,
          TeamID: this.selectedCompanies
        }
      })
      .then((res) => {
        this.deleting = false
        this.dialog = false;
        if(res.data.deleteTeamMember.code === 0) {
          this.$notify.success("Member Deleted");
          this.$emit('onArchiveStaff')
        }
        else if(res.data.deleteTeamMember.code === 7) {
          this.$notify.success("Error while deleting member");
        }
        else if(res.data.deleteTeamMember.code === 9) {
          this.$notify.success("Error while deleting member");
        }
        })
        .catch((error) => {
          this.deleting = false
          this.dialog = false;
          this.$notify.error("Something went wrong");
        });
    },
  },
});
