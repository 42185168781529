












































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SWITCH_USER } from 'src/utils/apollo-mutations';
import Vue from 'vue';
import { decodeToken } from '@shared/utils/commom.util';
import {  HttpService, LSS } from '@core/services';
import { LoginService } from '@services/login.service';
export default Vue.extend({
  props: {
    detailsForSwitchUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      switching: false,
      dialog: false,
      deleting: false,
    };
  },
   watch: {
    detailsForSwitchUser() {
      this.dialog = true;
    },
   },
  methods: {
    async getUserDetails(UserID, item) {
     
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
        switchTo: {
          TeamId: item.TeamID,
          Role: 'ADMIN',
          Company: item.CompanyName,
        },
      })
      const user = decodeToken(token);
      LSS.token = token;

      this.updateOrgs();
      LSS.company = item.Company;
      this.selectedOrg = item.Company;
      return user;
    },
    async switchUser() {
      this.deleting = true;
      try {
        const adminToken = LSS.token;
        await this.getUserDetails(decodeToken(LSS.token).UserId, this.detailsForSwitchUser.item);

        LSS.adminToken = adminToken;
        this.deleting = false;
        this.dialog = false;
        this.switching = true;

        setTimeout(() => {
          this.switching = false;
          this.$emit('onSwitchUser');
        }, 1500);

      } catch (error) {
        this.deleting = false;
        console.log(error);
      }
    },
    async updateOrgs() {
      if (LSS.token) {
        if (
          decodeToken(LSS.token).teams &&
          decodeToken(LSS.token).teams.length != 0
        ) {
          this.orgs = decodeToken(LSS.token).teams;
        } else {
          this.orgs = [
            {
              IsDisabled: false,
              CreatedOn: new Date(),
              Company: decodeToken(LSS.token).CompanyName,
              Role: decodeToken(LSS.token).Role,
              TeamId: decodeToken(LSS.token).TeamID,
            },
          ];
        }
        this.setCompany(decodeToken(LSS.token).CompanyName);
      }
    },
    setCompany(company) {
      LSS.company = company;
      this.selectedOrg = company;
    },
  }
});
