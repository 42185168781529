
















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { SignUpService } from '@services/signup.service';
import Vue from 'vue';
export default Vue.extend({
  props: {
    testUsersMetadata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    testUsersMetadata(data) {
      this.dialog = true;
    },
  },
  methods: {
    async deleteSmokeUsers() {
      const res = await SignUpService.delSmokeUser();
      if(res.code === 0) {
        this.dialog = false;
        this.$notify.success("Total Smoke test users is/are deleted - "+ res.data);
        this.$emit('onDeleteCompany')
      }
      else{
        this.$notify.error("Error while deleting smoke test users");
      }
    },
  },
});
