
















































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { REMOVE_SINGLE_USER } from 'src/utils/apollo-queries';
import Vue from 'vue';
export default Vue.extend({
  props: {
    removeUserMetadata: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    removeUserMetadata(data) {
      this.dialog = true;
    },
  },
  methods: {
    async removeSingle() {
      this.deleting = true
      await this.$apollo.query({
        query: REMOVE_SINGLE_USER,
        variables: {
          Email: this.removeUserMetadata.Email
        }
      })
      .then((res) => {
        this.deleting = false
        this.dialog = false;
        if(res.data.removeSingleUser.code === 0) {
          this.$notify.success("User Deleted");
        }
        if(res.data.removeSingleUser.code === 1) {
          this.$notify.error("Not authorized to delete");
        }
        else if(res.data.removeSingleUser.code === 7) {
          this.$notify.error("User does not exist");
        }
        else if(res.data.removeSingleUser.code === 9) {
          this.$notify.error("Error while deleting user");
        }
        this.$emit('onRemoveUser')
        })
        .catch((error) => {
          this.$emit('onRemoveUser')
          this.deleting = false
          this.dialog = false;
          this.$notify.error("Something went wrong");
        });

   
    },
  },
});
