

























































































 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 // @ts-nocheck
import Vue from 'vue';
import { CHANGE_AI_CREDITS, CHANGE_AI_CREDITS_GLOBALLY, CHANGE_AI_CREDITS_GLOBALLY_LIMITED } from '../utils/apollo-mutations';

export default Vue.extend({
  name: 'aICredits',
  props: {
    creditsDetails: {
      type: Object,
      required: false,
    },
    companyWithManualLimit:{
      type: Array,
      default:[]
    }
  },
  data() {
    return {
      valid: false,
      usageLimit: 0,
      saving: false,
      saving1: false,
      aiCreditsRules: [
        (v) => !!v || 'Limit is required',
        v => !!/^[0.1-9]*$/gi.test(v) || 'Please enter a valid value',
    ],
      aICreditsDialog: false,
      originalUsageLimit: 0
    };
  },
  watch: {     
    creditsDetails(data) {
      this.aICreditsDialog = true;
      this.originalUsageLimit = data.usageLimit;
      this.usageLimit = data.usageLimit;      
    },
  },
  methods: {  
    applyAction() {
      switch(this.creditsDetails.mode) {
        case 'individual': {
          this.changeCreditsIndividual(this.creditsDetails.TeamID, Number(this.usageLimit))
          break;
        }
        case 'global': {
          this.changeCreditsGlobal(Number(this.usageLimit))
          break;
        }
      }
    },
    async changeCreditsIndividual(TeamID, limit) {
      this.saving = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_AI_CREDITS,
          variables: {
            TeamID,
            limit,
          },
        });
        
        if(result.data.changeCredits.successMessage) {
          this.$notify.success('AI Credits limit updated.');
        } else {
          throw new Error('UNKNOWN')
        }
      } catch(error) {
        this.$notify.error('An error occurred while trying to update AI credits for the user.');
      }
      
      this.saving = false;
      this.aICreditsDialogClose()
      this.$emit('done');
    },
    async changeCreditsGlobal(limit) {
      this.saving = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_AI_CREDITS_GLOBALLY,
          variables: {
            limit,
          },
        });
      
        if(result.data.changeCreditsGlobally.successMessage) {
          this.$notify.success('AI Credits limit updated for all users.');
        } else {
          throw new Error('UNKNOWN')
        }
      } catch(error) {
        this.$notify.error('An error occurred while trying to update AI credits for the user.');
      }
      
      this.saving = false;
      this.aICreditsDialogClose()
      this.$emit('done');
    },
    async changeCreditsGlobalLimited() {
      const limit = Number(this.usageLimit)
      this.saving1 = true
      try {
        const result = await this.$apollo.mutate({
          mutation: CHANGE_AI_CREDITS_GLOBALLY_LIMITED,
          variables: {
            limit,
          },
        });
      
        if(result.data.changeCreditsGloballyLimited.successMessage) {
          this.$notify.success('AI Credits limit updated for limited users.');
        } else {
          throw new Error('UNKNOWN')
        }
      } catch(error) {
        this.$notify.error('An error occurred while trying to update AI credits for the user.');
      }
      
      this.saving1 = false
      this.aICreditsDialogClose()
      this.$emit('done');
    },
    aICreditsDialogClose() {
      
      this.aICreditsDialog = false;
    },
  },
});
